var message = $( "<div class='s31-filterError alert alert-warning' style='display: none;'>Prosím vyberte značku.</div>");
var itemClicked = false;
const categoryContEl = document.querySelector('.s31-listing-leftmenu');
const categoryEl = document.querySelector('.s31-listing-leftmenu-categories');

var Multifilters = function() {
	var choicesSelector = ".s31-multifilters [type=checkbox], .s31-multifilters [type=radio]",
		choicesCheckedSelector = ".s31-multifilters [type=checkbox]:checked, .s31-multifilters [type=radio]:checked",
		paginationLinkSelector = ".s31-pagination a.s31-pagination-item",
		loadMoreSelector = ".s31-listing-loadMore",
		sortingLinkSelector = ".s31-sorting-menu a",
		sortingLinkSelectSelector = ".s31-mobile-sort select",
		rangeCustomContainerSelector = ".s31-multifilters .s31-multifilters-pricefromto",
		searchInputSelector = ".s31-multifilters .s31-multifilters-findbrand input[type='text']",
		firstLettersSelector = ".s31-multifilters .s31-multifilters-square",
		gridTemplateAnchorSelector = ".s31-template-menu a.s31-template-item",
		cancelFilterAnchorSelector = ".s31-multifilters .s31-multifilters-cancel",
		cancelAllFilterAnchorSelector = ".s31-sorting-filter-cancel-all",
		cancelOneValueFilterAnchorSelector = ".s31-sorting-filter-cancel-one",
		labelAnchorSelector = "label.s31-multifilters-item a",
		openedFilters = [],
		priceFilterValue = null,
		searchInputs = [];

	var filtersToUrl = function(filters) {
		var parameters = "";
		for (var filterName in filters) {
			for (var value in filters[filterName]) {
				if (filters[filterName].hasOwnProperty(value) && typeof filters[filterName][value] != "function") {
					parameters += encodeURIComponent(filterName) + "=" + encodeURIComponent(filters[filterName][value]) + "&";
				}
			}
		}

		return parameters.slice(0, -1);
	};

	var sendRequest = function(url, parameters, historyUrl, appendProducts, scrollToTopPagination) {
		if (parameters.length > 0) {
			url += "?" + parameters;
		}

		$.post(url, function(payload) {
			if (typeof historyUrl !== "undefined") {
				window.history.replaceState({ filters: 1 }, document.title, historyUrl);
			}

			if (payload.hasOwnProperty("content")) {
				var $payload = $(payload.content);

				Multifilters.restoreOpenedFiltersAfterUpdate($payload);
				Multifilters.restoreSearchInputs($payload);
				$(".s31-multifilters").html($payload.filter(".s31-multifilters").eq(0).html());

				if (typeof appendProducts !== "undefined" && appendProducts) {
					$("#products").append($payload.filter("#products").html());
				} else {
					$("#products").html($payload.filter("#products").html());
				}

				$("#products").prop("class", $payload.filter("#products").prop("class"));
				$(".s31-sorting").html($payload.find(".s31-sorting").eq(0).html());
				$(".s31-template-menu").html($payload.filter(".s31-template-menu").eq(0).html());
				$(".s31-sorting-filters").html($payload.filter(".s31-sorting-filters").eq(0).html());

				var paginationInPayload = $payload.filter(".s31-pagination");
				if (paginationInPayload.length > 0) {
					$(".s31-pagination").html($payload.filter(".s31-pagination").eq(0).html());
				} else {
					$(".s31-pagination").html("");
				}

				var loadMoreInPayload = $payload.filter(".s31-listing-loadMore");
				if (loadMoreInPayload.length > 0) {
					$(".s31-listing-loadMore").show().html(loadMoreInPayload.eq(0).html());
					$(".s31-pagination").removeClass("s31-last-page");
				} else {
					$(".s31-listing-loadMore").hide();
					$(".s31-pagination").addClass("s31-last-page");
				}

				$(".s31-listing-prod-count").html($payload.filter(".s31-listing-prod-count").eq(0).html());

				var productCount = $payload.filter(".s31-listing-prod-count").eq(0).children("span").html();
				var productSuffix = filtersMoreProducts;
				if (productCount == 1) {
					productSuffix = filtersProduct;
				} else if (productCount > 1 && productCount < 5) {
					productSuffix = filtersProducts;
				}

				$(".s31-mobile-filters-show-box button .s31-normalText").html(filtersShow + " " + productCount + " " + productSuffix);

				if (typeof scrollToTopPagination !== "undefined" && scrollToTopPagination) {
					var $sorting = $(".s31-sorting");

					if ($sorting.length > 0 && !$sorting.eq(0).isInViewport()) {
						window.scrollTo(window.scrollX, $sorting.eq(0).offset().top - 10);
					}
				}

				document.dispatchEvent(new CustomEvent('s31shop.multifilters_applied',{}));
				hide31Spinner();

				var hashParams = getHashParams();

				if (hashParams.hasOwnProperty("page") && hashParams.page > 1) {
					$(".s31-listing-bottom-des").hide();
				} else {
					$(".s31-listing-bottom-des").show();
				}
			}
		});
	};

	var getRequestUrl = function() {
		if (typeof filterBaseUrl !== "undefined") {
			return filterBaseUrl;
		}

		if (location.pathname.startsWith("/s/")) {
			return location.pathname.replace("/s/", "/ajax/multifilters/search/");
		} else {
			return location.pathname.replace("/", "/ajax/multifilters/by-taxon/");
		}
	};

	var rangeCustomInputTimeouts = {};
	var initializeRangeCustomInputs = function() {
		$(document).on("change", rangeCustomContainerSelector + " input[name='from']", function() {
			var fromValue = parseInt(this.value.replace(/\s/g,'')) || 0,
				toValue = parseInt($(this).parents(rangeCustomContainerSelector).find("input[name='to']").val().replace(/\s/g,'')) || 0,
				$this = $(this);

			if (areRangeValuesValid(fromValue, toValue)) {
				clearTimeout(rangeCustomInputTimeouts[$this.data("parameter")]);
				rangeCustomInputTimeouts[$this.data("parameter")] = setTimeout(function() {
					$this.parents(".s31-multifilters-inside").find(".s31-multifilters-item input[type='radio']:checked").prop('checked', false);
					shows31Spinner();
					Multifilters.applyFilters(null, Multifilters.getActiveSorting(), false, false);
				}, 600);
			}
		});

		$(document).on("change", rangeCustomContainerSelector + " input[name='to']", function() {
			var fromValue = parseInt($(this).parents(rangeCustomContainerSelector).find("input[name='from']").val().replace(/\s/g,'')) || 0,
				toValue = parseInt(this.value.replace(/\s/g,'')) || 0,
				$this = $(this);

			if (areRangeValuesValid(fromValue, toValue)) {
				clearTimeout(rangeCustomInputTimeouts[$this.data("parameter")]);
				rangeCustomInputTimeouts[$this.data("parameter")] = setTimeout(function() {
					$this.parents(".s31-multifilters-inside").find(".s31-multifilters-item input[type='radio']:checked").prop('checked', false);
					shows31Spinner();
					Multifilters.applyFilters(null, Multifilters.getActiveSorting(), false, false);
				}, 600);
			}
		});
	};

	var areRangeValuesValid = function(fromValue, toValue) {
		return (fromValue !== null && toValue !== null && fromValue > -1 && toValue > -1 && fromValue < toValue);
	};

	var initializeSearchInputs = function() {
		$(document).on("keyup", searchInputSelector, function() {
			var value = $(this).val().toLowerCase();

			searchInChoices($(this), value);
		});
	};

	var searchInChoices = function($this, searchValue) {
		var activeLetters = [];

		$this.parents(".s31-multifilters-inside").find(".s31-multifilters-square.active").each(function(index, element) {
			activeLetters.push($(element).text());
		});

		if (activeLetters.length > 0) {
			$this.parents(".s31-multifilters-inside").find(".s31-multifilters-item").filter(function () {
				var show = false;

				for (var i in activeLetters) {
					var letter = activeLetters[i];

					if (show == false && $(this).text().toLowerCase().lastIndexOf(letter.toLowerCase(), 0) > -1) {
						show = true;
					}
				}

				$(this).toggle(show);
			});
		} else {
			$this.parents(".s31-multifilters-inside").find(".s31-multifilters-item").show();
		}

		$this.parents(".s31-multifilters-inside").find(".s31-multifilters-item").filter(function () {
			if ($(this).css("display") == "none") {
				return;
			}

			$(this).toggle($(this).text().toLowerCase().indexOf(searchValue) > -1);
		});
	};

	return {
		init: function() {
			$(document).on("change", choicesSelector, function() {
				shows31Spinner();
				Multifilters.applyFilters(null, Multifilters.getActiveSorting(), false, false);
			});

			$(document).on("click", paginationLinkSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				var sorting = Multifilters.getActiveSorting();

				Multifilters.applyFilters($(this).data("page"), sorting);
			});

			$(document).on("click", loadMoreSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				Multifilters.loadNextPage();
			});

			$(document).on("click", sortingLinkSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				Multifilters.applyFilters(null, $(this).data("sorting"))
			});

			$(document).on("change", sortingLinkSelectSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				Multifilters.applyFilters(null, $(this).find("option:selected").data("sorting"));
				
			});

			$(document).on("click", firstLettersSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				$(this).toggleClass("active");

				var searchValue = $(this).parents(".s31-multifilters-inside").find(".s31-multifilters-findbrand input[type='text']").val().toLowerCase();

				searchInChoices($(this), searchValue);
				
			});

			$(document).on("click", gridTemplateAnchorSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				$(gridTemplateAnchorSelector + ".active").removeClass("active");
				$(this).addClass("active");

				$.get(this.href, function() {
					var sorting = Multifilters.getActiveSorting(),
						page = Multifilters.getCurrentPage();

					Multifilters.applyFilters(page, sorting);
				});
			});

			$(document).on("click", cancelFilterAnchorSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				var filtersToCancel = [ $(this).data("filter") ];

				Multifilters.applyFilters(null, Multifilters.getActiveSorting(), false, false, filtersToCancel);
			});

			$(document).on("click", cancelOneValueFilterAnchorSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				if (typeof $(this).data("value") === "undefined") {
					var filtersToCancel = [ $(this).data("filter") ];

					Multifilters.applyFilters(null, Multifilters.getActiveSorting(), false, false, filtersToCancel);
				} else {
					var filterValuesToCancel = {};
					filterValuesToCancel[$(this).data("filter")] = [$(this).data("value")];

					Multifilters.applyFilters(null, Multifilters.getActiveSorting(), false, false, null, filterValuesToCancel);
				}
			});

			$(document).on("click", cancelAllFilterAnchorSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				var filtersToCancel = true;

				Multifilters.applyFilters(null, Multifilters.getActiveSorting(), false, false, filtersToCancel);
			});

			$(document).on("click", labelAnchorSelector, function(e) {
				e.preventDefault();
				shows31Spinner();

				$(this).parent().click();
				itemClicked = true;
			});

			initializeRangeCustomInputs();
			initializeSearchInputs();

			this.checkHashArguments();
		},

		applyFilters: function(page, sorting, appendProducts, scrollToTopPagination, filtersToCancel, filterValuesToCancel) {
			var url = getRequestUrl(),
				filters = this.getActiveFilters(filtersToCancel, filterValuesToCancel);
			itemClicked = false;

			if (!filters.hasOwnProperty("criteria[priceRange]")) {
				priceFilterValue = null;
			}

			if (filters.hasOwnProperty("criteria[priceRange]") && filters["criteria[priceRange]"][0] !== priceFilterValue) {
				priceFilterValue = filters["criteria[priceRange]"][0];
				let minMax = filters["criteria[priceRange]"][0].split(";");
				min = parseFloat((minMax[0] / 100).toFixed(2));
				max = parseFloat((minMax[1] / 100).toFixed(2));

				gaTrackingDatalayerPush(gaTrackingEventTrackingNoCallBackPrototype('Filter', 'select',  "Cena - " + min + ":" + max));
			}

			if (typeof page !== "undefined" && page !== null && page != "1") {
				filters["page"] = [ page ];
			}

			if (typeof sorting === "object" && sorting !== null) {
				for (var sortingKey in sorting) {
					for (var sortingColumn in sorting[sortingKey]) {
						filters["sorting[" + sortingColumn + "]"] = [ sorting[sortingKey][sortingColumn] ];
					}
				}
			}

			if (typeof appendProducts === "undefined") {
				appendProducts = false;
			}

			if (typeof scrollToTopPagination === "undefined") {
				scrollToTopPagination = true;
			}

			openedFilters = [];
			$(".s31-multifilters-inside:visible").each(function(index, element) {
				openedFilters.push($(element).data("filter"));
			});

			searchInputs = [];
			$(searchInputSelector).each(function(index, element) {
				var data = {
					filter: $(element).parents(".s31-multifilters-inside").data("filter"),
					value: "",
					firstLetters: []
				};

				if (element.value != "") {
					data.value = element.value;
				}

				$(element).parents(".s31-multifilters-inside").find(".s31-multifilters-square.active").each(function(index, element) {
					data.firstLetters.push($(element).text());
				});

				if (data.value != "" || data.firstLetters.length > 0) {
					searchInputs.push(data);
				}
			});

			var parameters = filtersToUrl(filters);
			var historyUrl = parameters.length > 0 ? location.pathname + "#" + parameters : location.pathname;

			sendRequest(url, parameters, historyUrl, appendProducts, scrollToTopPagination);

		},

		getActiveFilters: function(filtersToCancel, filterValuesToCancel) {
			var filters = {};

			if (typeof filtersToCancel === "boolean" && filtersToCancel) {
				return filters;
			}

			if (typeof filtersToCancel === "undefined" || filtersToCancel === null) {
				filtersToCancel = [];
			}

			if (typeof filterValuesToCancel === "undefined" || filterValuesToCancel === null) {
				filterValuesToCancel = {};
			}

			$(choicesCheckedSelector).each(function(index, element) {
				for (var i in filtersToCancel) {
					var filterToCancelName = filtersToCancel[i];

					if (element.name.startsWith(filterToCancelName)) {
						return;
					}
				}

				for (var i in filterValuesToCancel) {
					var filterToCancelName = i;

					if (element.name.startsWith(filterToCancelName)) {
						for (var ii in filterValuesToCancel[i]) {
							if (element.value == filterValuesToCancel[i][ii]) {
								return;
							}
						}
					}
				}

				if (typeof filters[element.name] === "undefined") {
					filters[element.name] = [];
				}

				filters[element.name].push(element.value);
			});

			$(rangeCustomContainerSelector).each(function (index, element) {
				var fromValue = parseInt($(this).find("input[name='from']").val().replace(/\s/g,'')) || 0,
					toValue = parseInt($(this).find("input[name='to']").val().replace(/\s/g,'')) || 0,
					fromInput = $(this).find("input[name='from']"),
					max = $(this).find("input[name='to']").data("max");

				if (typeof fromInput.data("changed") == "undefined" || fromInput.data("changed") == "0") {
					return;
				}

				for (var i in filtersToCancel) {
					var filterToCancelName = filtersToCancel[i];

					if (fromInput.data("parameter").startsWith(filterToCancelName)) {
						return;
					}
				}

				if (areRangeValuesValid(fromValue, toValue)) {
					if (fromInput.data("multiplier")) {
						var multiplier = parseInt(fromInput.data("multiplier"));
						fromValue *= multiplier;
						toValue *= multiplier;
					}

					if (typeof filters[fromInput.data("parameter")] === "undefined" && toValue != max) {
						filters[fromInput.data("parameter")] = [];
						filters[fromInput.data("parameter")].push(fromValue + ";" + toValue);
					}
				}
			});

			return filters;
		},

		restoreOpenedFiltersAfterUpdate: function(context) {
			for (var i in openedFilters) {
				var openedFilter = openedFilters[i],
					filterBlock = $(".s31-multifilters-inside[data-filter='" + openedFilter + "']", context);

				if (filterBlock.parent().hasClass("s31-multifilters-disabled")) {
					continue;
				}

				if (!filterBlock.is(":visible")) {
					filterBlock.prop("style", "display: block");
					filterBlock.prev().find("svg").addClass("opened");
					filterBlock.prev().addClass("opened");
				}
			}
		},

		restoreSearchInputs: function(context) {
			for (var i in searchInputs) {
				var searchInput = searchInputs[i],
					$input = $(".s31-multifilters-inside[data-filter='" + searchInput.filter + "'] .s31-multifilters-findbrand input[type='text']", context);

				$input.attr("value", searchInput.value);

				if (searchInput.firstLetters.length > 0) {
					$(".s31-multifilters-inside[data-filter='" + searchInput.filter + "'] .s31-multifilters-square", context).each(function(index, element) {
						if (searchInput.firstLetters.indexOf($(element).text()) > -1) {
							$(element).addClass("active");
						}
					});
				}

				searchInChoices($input, searchInput.value);
			}
		},

		checkHashArguments: function() {
			var params = getHashParams(),
				filters = {};

			for (var paramName in params) {
				var paramValue = params[paramName];

				if (paramName.lastIndexOf("criteria[", 0) === 0) {
					if (typeof filters[paramName] === "undefined") {
						filters[paramName] = [];
					}

					if (Array.isArray(paramValue)) {
						for (var i in paramValue) {
							filters[paramName].push(paramValue[i]);
						}
					} else {
						filters[paramName].push(paramValue);
					}
				}

				if (paramName.lastIndexOf("sorting[", 0) === 0) {
					if (typeof filters[paramName] === "undefined") {
						filters[paramName] = [];
					}

					if (Array.isArray(paramValue)) {
						for (var i in paramValue) {
							filters[paramName].push(paramValue[i]);
						}
					} else {
						filters[paramName].push(paramValue);
					}
				}

				if (paramName === "page") {
					filters["page"] = [paramValue];
				}
			}

			var parameters = filtersToUrl(filters);
			if (parameters.length > 0) {
				sendRequest(getRequestUrl(), parameters);
			}
		},

		loadNextPage: function() {
			var activeItem = $(".s31-pagination").eq(0).children(".s31-pagination-item.active"),
				nextItems = activeItem.nextAll(".s31-pagination-item"),
				currentPage = parseInt(Multifilters.getCurrentPage(), 10);

			if (nextItems.length > 0) {
				Multifilters.applyFilters(currentPage + 1, Multifilters.getActiveSorting(), true, false);
			}
		},

		getCurrentPage: function() {
			var page = 1,
				activePageItem = $(".s31-pagination").eq(0).find(".active");

			if (activePageItem.length > 0) {
				page = activePageItem.data("page");
			}

			return page;
		},

		getActiveSorting: function() {
			var sorting = null,
				activeSortingItem = $(".s31-sorting-menu").eq(0).find(".active");

			if (activeSortingItem.length > 0) {
				sorting = activeSortingItem.data("sorting");
			}

			return sorting;
		}
	};
}();

$(function() {

	if (mqsm.matches) {
		//SM nad 575px
		$(".s31-listing-leftmenu").on("scroll", function (e) {
			var elWidth = categoryEl.offsetWidth;
			var elContScroll = categoryContEl.scrollLeft;
			var elContWidth = categoryContEl.offsetWidth;
	
			if(elContScroll + elContWidth >= elWidth) {
				$(".s31-listing-leftmenu-gradient").fadeOut(200);
			} else {
				$(".s31-listing-leftmenu-gradient").fadeIn(200);
			}
		});
	} else {
		$(".s31-listing-leftmenu").on("scroll", function (e) {
			var elWidth = categoryEl.offsetWidth;
			var elContScroll = categoryContEl.scrollLeft;
			var elContWidth = categoryContEl.offsetWidth;
	
			if(elContScroll + elContWidth >= elWidth) {
				$(".s31-listing-leftmenu-gradient").fadeOut(200);
			} else {
				$(".s31-listing-leftmenu-gradient").fadeIn(200);
			}
		});
	}

	Multifilters.init();

	$(document).on("click", ".s31-multifilters-disabled .s31-multifilters-head", function () {
		$( ".s31-multifilters-disabled" ).append( message );
		$( ".s31-filterError" ).slideDown();
	});


	$(document).on("click", ".s31-multifilters-head", function(e) {
		var filterName = $(this).data("filter-name").trim();
		var action = "open"
		if ($(this).hasClass("opened")) {
			action = "close";
		}
		gaTrackingDatalayerPush(gaTrackingEventTrackingNoCallBackPrototype('Filter', action, filterName));

		if ($(this).parent().hasClass("s31-multifilters-disabled")) {
			return;
		}
		if ($(this).hasClass("s31-notClickable")) {
			return;
		}

		$(this).toggleClass("opened");
		$(this).next(".s31-multifilters-inside, .s31-listing-leftmenu-categories").slideToggle();

		$(this).next(".s31-multifilters-colors-box, .s31-multifilters-size-box, .s31-multifilters-rating-box").slideToggle().css("display","flex");
	});

	$(document).on("click", ".s31-multifilters-item", function(e) {
		if (itemClicked) {
			var filterName = $(this).parent().prev().data("filter-name").trim();
			var choiceName = $(this).data("choice-name").trim();
			var action = "select";
			gaTrackingDatalayerPush(gaTrackingEventTrackingNoCallBackPrototype('Filter', action, filterName + " - " + choiceName));
		}
		itemClicked = true;
	});

	$(document).on("click", ".s31-multifilters-colors, .s31-multifilters-size", function(e) {
		if (itemClicked) {
			var filterName = $(this).parent().prev().data("filter-name").trim();
			var choiceName = $(this).data("choice-name").trim();
			var action = "select";
			gaTrackingDatalayerPush(gaTrackingEventTrackingNoCallBackPrototype('Filter', action, filterName + " - " + choiceName));
		}
		itemClicked = true;
	});
});
		

function shows31Spinner() {
	const loaderBack = document.getElementById("s31-spinnerBack");
	const loaderCont = document.getElementById("s31-spinner");

	$(".s31-mobile-filters-show-box button").addClass("s31-loadingActive");

	loaderBack.style.display = "block";
	loaderCont.style.display = "block";
}

function hide31Spinner() {
	const loaderBack = document.getElementById("s31-spinnerBack");
	const loaderCont = document.getElementById("s31-spinner");

	$(".s31-mobile-filters-show-box button").removeClass("s31-loadingActive");

	loaderBack.style.display = "none";
	loaderCont.style.display = "none";
}

